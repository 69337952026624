@font-face {
    font-family: uthmani;
    src: url('./uthmani.woff2');
}

.wrapper {
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    margin: 0;
    /*background: rgb(240, 255, 245);*/
    background: linear-gradient(135deg, #345F97, #07171D);
}

.vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: space-evenly;
    box-sizing: border-box;

}

.blur  {
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(15px);
    transition: backdrop-filter 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 99;
}

.vertical h1 {
    margin: 0;
}

.addbtn button {
    border: none;
    background: none;
    font-size: 2.5em;
    font-weight: 200;
    font-family: Kodchasan;
    color: white;
}

.addbtn {
    /*background: green;*
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    aspect-ratio: 1/1;
    border: 3px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
    */

    /*
    box-shadow: inset -7px -7px 13px rgba(135, 163, 199, 0.2),
              inset -2px -2px 4px rgba(79, 111, 153, 0.5),
              inset 2px 2px 2px rgba(92, 119, 153, 0.075),
              inset 10px 10px 40px rgba(0, 0, 0, .35);
              */
    box-shadow: inset 15px 15px 30px #122538,
        inset -15px -15px 30px rgba(36, 75, 114, 0.65);
    width: 50%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.moneyContainer {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
}

.dua {
    font-size: 1.2em;
    letter-spacing: 1px;
    font-family: uthmani;
    color: rgba(255,255,255,0.4);
    margin-top: 2em;
    /*color: #2e5375;
    text-shadow: 3px 3px 4px rgba(0,0,0,1);*/
}

.moneyContainer * {
    grid-row: 1;
    grid-column: 1;
}

.moneyAmount {
    font-family: Kodchasan;
    color: white;
    background: linear-gradient(90deg, #FFFFFF, #96CAFF);
    font-size: 6.5em;
    font-weight: 200;
    /*text-shadow: 4px 7px 4px rgba(0, 0, 0, 0.65);*/
    text-align: center;
    z-index: 2;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.moneyAlt {
    font-family: Kodchasan;
    color: white;
    z-index: 1;
    font-size: 6.5em;
    font-weight: 200;
    text-shadow: 4px 7px 4px rgba(0, 0, 0, 0.65);
    text-align: center;
}

.clear {
    background: rgba(0, 0, 0, 0.1);
    border: none;
    font-size: 1em;
    font-weight: bold;
    color: rgba(151, 104, 104, 0.8);
    text-transform: uppercase;
    padding: 4px 20px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

.header {
    width: 100vw;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Kodchasan;
    text-transform: uppercase;
    color: white;
    font-size: 24px;
    text-align: center;
    letter-spacing: 16.4px;

}

.divider {
    margin: auto;
    width: 30%;
    margin-top: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.8);
}

.divider2 {
    margin: 0 auto;
    width: 70%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.settings {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    margin: 0;
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 999;
    font-family: kodchasan;
    color: white;
    gap: 1em;
}

.clearButton {
    padding: 10px 30px;
    border-radius: 17px;
    border: none;
    background: rgba(255,255,255,0.3);
    color: white;
    text-transform: uppercase;
    font-family: kodchasan;
    font-size: 1.2em;
    backdrop-filter: blur(10px) saturate(2);
    border: 4px solid rgba(255,255,255,0.1);
    box-shadow: 3px 3px 8px rgba(0,0,0,0.6);
}

.overrideInput {
    padding: 10px 30px;
    border-radius: 17px;
    border: none;
    background: rgba(255,255,255,0.3);
    color: white;
    text-transform: uppercase;
    font-family: kodchasan;
    font-size: 1.2em;
    backdrop-filter: blur(10px) saturate(2);
    border: 2px solid rgba(255,255,255,0.1);
    box-shadow: 3px 3px 8px rgba(0,0,0,0.6);
}